<template>
  <form>
    <hr />
    <div class="row">
      <p class="flex xs12 textTit">
        <strong>{{ $t("teams.inputs.newTeamInput") }}</strong>
      </p>
      <div class="flex xs12 sm4">
        <strong
          ><label for="">{{ $t("layout.form.regionInput") }}</label></strong
        >
        <p>{{ $t(region.name) }}</p>
      </div>
      <div class="flex xs12 sm4">
        <strong
          ><label for="">{{ $t("layout.form.countryInput") }}</label></strong
        >
        <p>{{ $t(country.name) }}</p>
      </div>
      <div class="flex xs12 sm4">
        <strong
          ><label for="">{{ $t("layout.form.districtInput") }}</label></strong
        >
        <p>{{ $t(district.name) }}</p>
      </div>
      <div class="flex xs12 sm4">
        <strong
          ><label for="">{{ $t("teams.inputs.leaderInput") }}</label></strong
        >
        <p>{{ leader.name }}</p>
      </div>
      <div class="flex xs12 sm4">
        <strong
          ><label for="">{{ $t("layout.form.emailInput") }}</label></strong
        >
        <p>{{ team && team.email ? team.email : "" }}</p>
      </div>
      <div class="flex xs12 sm4">
        <strong
          ><label for=""> {{ $t("teams.inputs.codeInput") }}</label></strong
        >
        <p>{{ team && team.code ? team.code : "" }}</p>
      </div>
      <div class="flex xs12 sm4">
        <strong
          ><label for="">{{ $t("teams.inputs.languagesInput") }}</label></strong
        >
        <p>{{ team && team.languages ? team.languages : "" }}</p>
      </div>
      <div class="flex xs12 sm8">
        <strong
          ><label for="">{{
            $t("teams.inputs.zoneDescriptionInput")
          }}</label></strong
        >
        <p>{{ team && team.zoneDescription ? team.zoneDescription : "" }}</p>
      </div>
    </div>
    <hr />
    <div class="row">
      <p class="flex xs12 textTit">
        <strong>{{ $t("teams.tabs.members.title") }}</strong>
      </p>
      <div class="flex xs12 sm3" v-for="(option, id) in membersList" :key="id">
        <strong
          ><p>{{ option.name }}</p></strong
        >
        <p>{{ option.email }}</p>
        <p>{{ $t(option.role) }}</p>
      </div>
    </div>
    <hr />
    <div class="row">
      <p class="flex xs12 textTit">
        <strong>{{ $t("teams.wizard.form.planning") }}</strong>
      </p>
      <div class="flex xs12 sm4" v-for="(option, id) in plan" :key="id">
        <strong
          ><h4>{{ option.date }}</h4></strong
        >
        <div class="flex xs12">
          <strong
            ><p>{{ $t("teams.inputs.presentationsNumberInput") }}</p></strong
          >
          <p>{{ option.presentations }}</p>
        </div>
        <div class="flex xs12">
          <strong
            ><p>{{ $t("teams.inputs.motherChurchInput") }}</p></strong
          >
          <p>{{ option.motherChurch }}</p>
        </div>
        <div class="flex xs12">
          <strong
            ><p>{{ $t("teams.inputs.preachingPointInput") }}</p></strong
          >
          <p>{{ option.preachingPoint }}</p>
        </div>
        <div class="flex xs12">
          <strong
            ><p>{{ $t("teams.inputs.preachingLeaderInput") }}</p></strong
          >
          <p>{{ option.leaderName }}</p>
        </div>
      </div>
    </div>
    <hr />
    <div class="row">
      <p class="flex xs12 textTit">
        <strong>{{ $t("teams.wizard.form.preaching") }}</strong>
      </p>
      <div class="flex xs12 sm4" v-for="(option, id) in questions" :key="id">
        <strong
          ><h4>{{ $t(option.title) }}</h4></strong
        >
        <p>{{ option.value }}</p>
      </div>
    </div>
    <hr />
    <div class="flex xs12"></div>
  </form>
</template>

<script>
import Languages from "@/data/Languages";
import languages from "@/router/configuration/languages";
import { validatorMixin } from "@/services/validator";
import { mapGetters } from "vuex";

const VueSelectImage = () =>
  import(/* webpackPrefetch: true */ "vue-select-image");
require("vue-select-image/dist/vue-select-image.css");

export default {
  name: "team-summary",
  components: {
    VueSelectImage,
  },
  props: {
    loading: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    team: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
    teamMembers: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
    plan: {
      type: Array,
      required: false,
      default: function () {
        return {};
      },
    },
    questions: {
      type: Array,
      required: false,
      default: function () {
        return {};
      },
    },
  },
  mixins: [validatorMixin],
  computed: {
    ...mapGetters(["currentUser", "currentLocale"]),
  },
  data() {
    return {
      verifying: false,
      assignedError: false,
      noRegionalData: false,
      isLoading: {
        leaders: false,
        teams: false,
        code: false,
      },
      isError: {
        leaders: false,
        teams: false,
      },
      asignedPercentage: null,
      teamFilter: "&with-users=true",
      initialTeam: null,
      selectedTeam: null,
      locationFilters: ["regions", "countries", "districts"],
      country: {},
      region: {},
      district: {},
      leader: {},
      membersList: [],
      selectedDistrict: "",
      leadersList: [],
      selectedLeader: "",
      LanguagesLists: [],
      selectedLanguages: [],
      selectedEquipement: [],
    };
  },
  watch: {
    team: {
      deep: true,
      handler: function (val, oldVal) {
        this.updateData();
      },
    },
    questions: {
      deep: true,
      handler: function (val, oldVal) {},
    },
    plan: {
      deep: true,
      handler: function (val, oldVal) {
        if (!this.team.users) {
          return false;
        }
        const arr = this.team.users._ids;
        arr.forEach(async (element) => {
          try {
            const u = await this.$http.get("users/" + element);
            const member = { ...u.data.data };
            let rol = this.$t("groups.permission.Member");
            if (element === this.team.leader_id) {
              rol = this.$t("groups.permission.TeamLeader");
            }
            member.role = rol;
            this.membersList.push(member);
          } catch (err) {
            this.error = true;
            this.loading = false;
          }
        });
      },
    },
    currentLocale(val) {
      this.validateAll();
    },
  },
  created() {
    this.LanguagesLists = Languages.slice(0);
  },
  methods: {
    async updateData() {
      this.loading = true;
      this.error = false;

      let u = false;
      const countryId = this.team.country_id;
      try {
        u = await this.$http.get("countries/" + countryId);
      } catch (err) {
        this.error = true;
        this.loading = false;
        return;
      }

      this.country = { ...u.data.data };
      u = false;
      const distrId = this.team.district_id;
      try {
        u = await this.$http.get("districts/" + distrId);
      } catch (err) {
        this.error = true;
        this.loading = false;
        return;
      }

      this.district = { ...u.data.data };
      u = false;
      const regionid = this.team.region_id;
      try {
        u = await this.$http.get("regions/" + regionid);
      } catch (err) {
        this.error = true;
        this.loading = false;
        return;
      }
      this.region = { ...u.data.data };
      u = false;
      const userId = this.team.leader_id;
      try {
        u = await this.$http.get("users/" + userId);
      } catch (err) {
        this.error = true;
        this.loading = false;
        return;
      }
      this.leader = { ...u.data.data };
      this.loading = false;
    },
  },
};
</script>
<style lang="scss">
.equipement-style {
  display: flex;
  justify-content: center;

  &__wrapper {
    overflow: auto;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    margin: 0 12px 12px 0;
    float: left;

    @media only screen and (min-width: 1200px) {
      margin-left: 30px;
    }
  }

  &__thumbnail {
    padding: 6px;
    border: 1px solid #dddddd;
    display: block;
    // padding: 4px;
    line-height: 20px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    &--selected {
      background: #0088cc;

      .custom-style__img {
        zoom: 1.1;
      }
    }
  }

  &__img {
    -webkit-user-drag: none;
    display: block;
    width: 10vw;
    min-width: 150px;
    border-radius: 5px;
    margin-right: auto;
    margin-left: auto;
    cursor: pointer;
  }

  &__lbl {
    display: block;
    font-weight: bold;
    text-align: center;
  }
}
</style>
